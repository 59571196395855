import React, { useState } from "react";
import "./css/MainNavigation.css";
import { Link, useHistory, useLocation } from "react-router-dom";
import logo from "../media/logo.svg";

function MainNavigation(props) {
  const history = useHistory();
  const [openMenu, setOpenMenu] = useState(false);
  const LogOutHandler = () => {
    //clear the jwt token and then redirect to root page (login)
    localStorage.removeItem("potentially_sailor_JWT");
    localStorage.removeItem("potentially_sailor_UID");
    history.push({
      pathname: "/",
    });
  };

  const location = useLocation();

  return (
    <header className="head-nav">
      <a href="https://kazanseo.com">
        <img src={logo} className="App-logo head-logo" alt="logo" />
      </a>
      <div>
        <div className="current-color">{props.current}</div>
        <div className="current-separator"></div>
      </div>
      <div className="mobile-nav-parent">
        <div className={`mobile-nav ${openMenu ? "open" : ""}`} onClick={() => setOpenMenu(!openMenu)}>
          <span className="mobile-nav-line"></span>
          <span className="mobile-nav-line"></span>
          <span className="mobile-nav-line"></span>
        </div>
      </div>

      {openMenu ? <div className="mobile-nav-overlay" /> : <></>}
      <nav className={`nav-nav ${openMenu ? "open" : ""}`}>
        <div className="tools-header">
          <div className="current-color">Tools</div>
          <div className="current-separator"></div>
        </div>
        <ul className="nav-links">
          <li>
            <Link to="/detector">{location.pathname === "/detector" ? "[AI GPT-X Detector]" : "AI GPT-X Detector"}</Link>
          </li>
          <div className="separator-link"></div>
          <li>
            <Link to="/detection-log">{location.pathname === "/detection-log" ? "[AI Detection Log]" : "AI Detection Log"}</Link>
          </li>
          <div className="separator-link"></div>
          <li>
            <Link className="highlight-link" to="/emailsniper">
              {location.pathname === "/emailsniper" ? "[Get Email Sniper]" : "Get Email Sniper"}
            </Link>
          </li>
          <div className="separator-link"></div>
          <li>
            <Link to="/emailsearch">{location.pathname === "/emailsearch" ? "[Email Sniper]" : "Email Sniper"}</Link>
          </li>
          <div className="separator-link"></div>
          <li>
            <Link to="/emailsearchengine">{location.pathname === "/emailsearchengine" ? "[Email Search Engine]" : "Email Search Engine"}</Link>
          </li>
          <div className="separator-link"></div>
          <li>
            <Link to="/projects">{location.pathname === "/projects" ? "[Content Optimizer]" : "Content Optimizer"}</Link>
          </li>
          <div className="separator-link"></div>
          <li>
            <Link to="/keyword-clusters">{location.pathname === "/keyword-clusters" ? "[Discover Keywords]" : "Discover Keywords"}</Link>
          </li>
          <div className="separator-link"></div>
          <li>
            <Link to="/repeated-urls">{location.pathname === "/repeated-urls" ? "[SERP Overlap]" : "SERP Overlap"}</Link>
          </li>
          <div className="separator-link"></div>
          <li>
            <Link to="/keyword-sharing">{location.pathname === "/keyword-sharing" ? "[KW Sharing]" : "KW Sharing"}</Link>
          </li>
          <div className="separator-link"></div>
          <li>
            <Link to="/textron">{location.pathname === "/textron" ? "[TextronGPT (AI text)]" : "TextronGPT (AI text)"}</Link>
          </li>
          <div className="separator-link"></div>
          <li>
            <Link to="/text-extractor">{location.pathname === "/text-extractor" ? "[Text Extractor]" : "Text Extractor"}</Link>
          </li>
          <div className="separator-link"></div>
          <li>
            <Link to="/email-verifier">{location.pathname === "/email-verifier" ? "[Email Verifier]" : "Email Verifier"}</Link>
          </li>
          <div className="separator-link"></div>
          <li>
            <a href="https://www.facebook.com/groups/881290299446855" target="_blank" rel="noreferrer">
              FB Group
            </a>
          </li>
          <div className="separator-link"></div>
          <li>
            <a href="https://kazanseo.com/blog" target="_blank" rel="noreferrer">
              Blog & Guides
            </a>
          </li>
          <div className="separator-link"></div>
          <li className="logout-li">
            <button className="logout-button" onClick={LogOutHandler}>
              Log Out
            </button>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default MainNavigation;
