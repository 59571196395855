import React, { useState, useEffect, useContext } from "react";
import "./css/EmailSearcher.css";
import MainNavigation from "./MainNavigation";
import SailorContent from "../store/SailorContext";
import CheckJWTer from "../hooks/useCheckJWT";
import ResultsEmails from "./EmailsFound";
import ResultsEmailsEmails from "./EmailsFoundEmails";
import { Link } from "react-router-dom";

// var mock_results = [
//   {
//     email: "johndoe@joe.com",
//     phone: "+123-456-789",
//   },
//   {
//     email: "janedoe@joe.com",
//     phone: "+123-450-781",
//   },
// ];

var mock_results = {
  emails: ["johndoe@joe.com", "janedone@jane.com"],
  phones: ["+123-456-7890", "+123-450-781"],
  socials: ["instagram.com/johndoe", "youtube.com/janedoe"],
};

function EmailSearcher() {
  const [data] = CheckJWTer();
  const [domain, setDomain] = useState("");
  const [results, setResults] = useState();
  const [openBuilder, setOpenBuilder] = useState(false);
  const [email_subject, setEmailSubject] = useState("");
  const [email_body, setEmailBody] = useState("");
  const context = useContext(SailorContent);

  const domainChangeHandler = (event) => {
    setDomain(event.target.value);
  };

  const email_subject_ChangeHandler = (event) => {
    setEmailSubject(event.target.value);
  };

  const email_body_ChangeHandler = (event) => {
    setEmailBody(event.target.value);
  };

  const resulter = () => {
    console.log(mock_results);
    let z = "";
    if (mock_results.emails != null && mock_results.emails.length > 0) {
      z = mock_results.emails.map((arr) => <ResultsEmailsEmails item={arr} type={"email-log"} subject={email_subject} body={email_body} />);
    }
    let zp = "";
    if (mock_results.phones != null && mock_results.phones.length > 0) {
      zp = mock_results.phones.map((arr) => <ResultsEmails item={arr} type={"phones-log"} />);
    }
    let zs = "";
    if (mock_results.socials != null && mock_results.socials.length > 0) {
      zs = mock_results.socials.map((arr) => <ResultsEmails item={arr} type={"socials-log"} />);
    }

    const zall = [...z, zp, zs];
    setResults(zall);
  };

  //checks if you have a sub
  const get_emails = () =>
    fetch(context.api_url + "Emails/get-emails?domain=" + domain, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer  ${context.jwt}`,
      },
    })
      .then((r) => {
        return r.json();
      })
      .then((rx) => {
        console.log(rx);
        if (rx.hasOwnProperty("error")) {
          if (rx.error === "not verified") {
            setResults(
              <div className="bad-result">
                Please verify your account to access the free tier, all you have to do is click on the link we sent to your email.
              </div>
            );
          } else if (rx.error === "over free limit" && rx.hasOwnProperty("searches")) {
            setResults(
              <div className="bad-result">
                You've exhausted your searches for this month on the free tier, you've done {rx.searches} so far.{" "}
                <a className="ext-link" href="https://kazanseo.com/emailsniper">
                  Please upgrade to a pro plan to get access to 5 million searches per month here
                </a>
              </div>
            );
          } else {
            setResults(<div className="bad-result">You're all good but our servers caught fire, a team of monkeys with buckets has been dispatched</div>);
          }
          return;
        }
        if (rx.hasOwnProperty("emails") && rx.hasOwnProperty("phones") && rx.hasOwnProperty("socials")) {
          mock_results = rx;
          resulter();
        } else if (rx.hasOwnProperty("status") && rx.status === 401) {
          console.log("unauthorized");
          setResults(
            <div className="bad-result">
              You're not susbcribed, please do so in order to obtain millions of new emails per day
              <a className="ext-link" href="https://kazanseo.com/emailsniper" rel="noreferrer">
                Get Email Sniper Here
              </a>
            </div>
          );
        } else {
          setResults(<div className="bad-result">No results for your search</div>);
        }
      })
      .catch((er) => {
        console.log(er);
        setResults(
          <div className="bad-result">
            You're not susbcribed, please do so in order to obtain millions of new emails per day
            <a className="ext-link" href="https://kazanseo.com/emailsniper" rel="noreferrer">
              Get Email Sniper Here
            </a>
          </div>
        );
      });

  const submitHandler = (event) => {
    event.preventDefault();
    setResults([<div className="lds-hourglass"></div>]);
    get_emails();
  };

  useEffect(() => {
    resulter();
  }, []);

  return (
    <div>
      <MainNavigation current="Email Sniper" />
      <div className="email-box">
        <form className="compare-form-form" onSubmit={submitHandler}>
          <div className="email-swap-bulk-normal"><Link to="/emailsearch">[Email Search]</Link>  |  <Link to="/emailsearchbulk">Email Search Bulk</Link></div>
          <div className="email-form-top">
            <div className="compare-form-title">
              <label>Type a domain to start the search</label>
              <div className="compare-credit compare-instructions"><a href="https://kazanseo.com/blog/kazanseo-email-sniper-how-it-works/" target="_blank" rel="noreferrer">How it works</a></div>
              {/* <div className="searcher-subtitle">Or paste any URL</div> */}
              <input type="text" value={domain} onChange={domainChangeHandler} />
            </div>
          </div>
          <div className="email-form-actions">
            <button type="submit">Search for Emails</button>
          </div>
        </form>
        <div className="mail-builder">
          <div className={`${openBuilder ? "" : "hider"}`}>
            <div className="email-areas">
              <textarea type="text" value={email_subject} placeholder="subject you'll use" onChange={email_subject_ChangeHandler} />
              <textarea type="text" value={email_body} placeholder="body you'll use" onChange={email_body_ChangeHandler} />
            </div>
          </div>
          <button onClick={() => setOpenBuilder(!openBuilder)}>{`${openBuilder ? "hide" : "show"} mail builder`}</button>
        </div>
        <div className="click-to-copy">Click any result to copy it into clipboard</div>
        <div className="email-search-results-wrapper">{results}</div>
      </div>
    </div>
  );
}

export default EmailSearcher;
