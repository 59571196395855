import React, { useState, useEffect, useContext } from "react";
import "./css/EmailSearcher.css";
import MainNavigation from "./MainNavigation";
import SailorContent from "../store/SailorContext";
import CheckJWTer from "../hooks/useCheckJWT";
import ResultsEmails from "./EmailsFound";
import ResultsEmailsEmails from "./EmailsFoundEmails";

// var mock_results = [
//   {
//     email: "johndoe@joe.com",
//     phone: "+123-456-789",
//   },
//   {
//     email: "janedoe@joe.com",
//     phone: "+123-450-781",
//   },
// ];

var mock_results = {
    allcontacts: [
        {
            domain: "first.com",
            emails: ["johndoe@joe.com", "janedone@jane.com"],
            phones: ["+123-456-7890", "+123-450-781"],
            socials: ["instagram.com/johndoe", "youtube.com/janedoe"],
        },
        {
            domain: "second.com",
            emails: ["joejoe@joe.com", "janejane@jane.com"],
            phones: ["+123-456-7890", "+123-450-781"],
            socials: ["instagram.com/joejoe", "youtube.com/janejane"],
        },
    ],
    notcrawled: ["notcrawled.com", "notcrawled2.com"],
    paid_account: true
};
function EmailSearcherSearchEngine() {
    const [data] = CheckJWTer();
    const [domain, setDomain] = useState("");
    const [results, setResults] = useState();
    const [openBuilder, setOpenBuilder] = useState(false);
    const [openExport, setOpenExport] = useState(false);
    const [email_subject, setEmailSubject] = useState("");
    const [email_body, setEmailBody] = useState("");
    const [ErrorMessage, setErrorMessage] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const context = useContext(SailorContent);

    const domainChangeHandler = (event) => {
        setDomain(event.target.value);
    };

    const email_subject_ChangeHandler = (event) => {
        setEmailSubject(event.target.value);
    };

    const email_body_ChangeHandler = (event) => {
        setEmailBody(event.target.value);
    };

    const resulter = () => {
        console.log("printing mock_results from resulter function");
        console.log(mock_results);
        if (mock_results.hasOwnProperty("paid_account") && mock_results.paid_account === false) {
            mock_results.allcontacts.unshift({
                domain: (<a href="https://kazanseo.com/emailupgradeexperience"><div className="sign-up-please">Please Upgrade To See the Full Results</div></a>),
                emails: ["thiscouldbeyourlead@fullaccount.com", "thiscouldbeyourotherlead@fullaccount.com"],
                phones: ["+123-456-7890", "+123-450-781"],
                socials: ["instagram.com/joejoe", "youtube.com/janejane"],
            }, {
                domain: (<a href="https://kazanseo.com/emailupgradeexperience"><div className="sign-up-please">Please Upgrade To See the Full Results</div></a>),
                emails: ["thiscouldbeyourlead@fullaccount.com", "thiscouldbeyourotherlead@fullaccount.com"],
                phones: ["+123-456-7890", "+123-450-781"],
                socials: ["instagram.com/joejoe", "youtube.com/janejane"],
            },);
        }
        console.log("printing mock_results from resulter function");
        console.log(mock_results);
        let mz = "";
        mz = mock_results.allcontacts.map((marr) => {
            let z = "";
            if (marr.emails != null && marr.emails.length > 0) {
                z = marr.emails.map((arr) => <ResultsEmailsEmails item={arr} type={"email-log"} subject={email_subject} body={email_body} />);
            }
            let zp = "";
            if (marr.phones != null && marr.phones.length > 0) {
                zp = marr.phones.map((arr) => <ResultsEmails item={arr} type={"phones-log"} />);
            }
            let zs = "";
            if (marr.socials != null && marr.socials.length > 0) {
                zs = marr.socials.map((arr) => <ResultsEmails item={arr} type={"socials-log"} />);
            }
            const zall = [...z, zp, zs];

            return (
                <div className="email-results-wrapper">
                    <div className="email-results-domain">
                        <span>{marr.domain}</span>
                    </div>
                    {zall}
                </div>
            );
        });

        setResults(
            <div>
                <div>{mz}</div>
            </div>
        );
    };

    //checks if you have a sub
    const get_emails = (current_page) => {
        let cleaned_dts = domain.trim();
        let payload = { query: cleaned_dts, skip: current_page };
        console.log(payload);
        fetch(context.api_url + "Emails/get-emails-search", {
            method: "post",
            body: JSON.stringify(payload),
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer  ${context.jwt}`,
            },
        })
            .then((r) => {
                return r.json();
            })
            .then((rx) => {
                // console.log("printing get_emails function return to the api");
                // console.log(rx);
                if (rx.hasOwnProperty("error")) {
                    if (rx.error === "not verified") {
                        setResults(
                            <div className="bad-result">
                                Please verify your account to access the free tier, all you have to do is click on the link we sent to your email.
                            </div>
                        );
                    } else if (rx.error === "over free limit" && rx.hasOwnProperty("searches")) {
                        setResults(
                            <div className="bad-result">
                                You've exhausted your searches for this month on the free tier, you've done {rx.searches} so far.{" "}
                                <a className="ext-link" href="https://kazanseo.com/emailsniper">
                                    Please upgrade to a pro plan to get access to 5 million searches per month here
                                </a>
                            </div>
                        );
                    } else {
                        setResults(<div className="bad-result">You're all good but our servers caught fire, a team of monkeys with buckets has been dispatched</div>);
                    }
                    return;
                }
                if (rx.hasOwnProperty("allcontacts") && rx.hasOwnProperty("notcrawled")) {
                    if (rx.allcontacts === null) {
                        setResults(
                            <div className="bad-result">
                                not enough results, try another query
                            </div>
                        );
                    }
                    mock_results = rx;
                    setOpenExport(true);
                    resulter();
                } else if (rx.hasOwnProperty("status") && rx.status === 401) {
                    console.log("unauthorized");
                    setResults(
                        <div className="bad-result">
                            You're not susbcribed, please do so in order to obtain millions of new emails per day
                            <a className="ext-link" href="https://kazanseo.com/emailsniper" rel="noreferrer">
                                Get Email Sniper Here
                            </a>
                        </div>
                    );
                } else {
                    setResults(<div className="bad-result">No results for your search</div>);
                }
            })
            .catch((er) => {
                console.log(er);
                setResults(
                    <div className="bad-result">
                        You're not susbcribed, please do so in order to obtain millions of new emails per day
                        <a className="ext-link" href="https://kazanseo.com/emailsniper" rel="noreferrer">
                            Get Email Sniper Here
                        </a>
                    </div>
                );
            });
    };

    const submitHandler = (event) => {
        event.preventDefault();
        setOpenExport(false);
        setResults([<div className="lds-hourglass"></div>]);
        //grab all the urls and send them with get_emails
        get_emails(0);
    };

    function prev_click() {
        setResults([<div className="lds-hourglass"></div>]);
        if (currentPage <= 0) {
            setCurrentPage(0);
            get_emails(0);
            setErrorMessage("Can't go below page 0, showing results for page 0 (1)");
            return null;
        }
        var cur_page = currentPage;
        cur_page--;
        setCurrentPage(cur_page);
        get_emails(cur_page);
    }
    function next_click() {
        setResults([<div className="lds-hourglass"></div>]);
        var cur_page = currentPage;
        cur_page++;
        setCurrentPage(cur_page);
        get_emails(cur_page);
    }

    useEffect(() => {
        resulter();
    }, []);

    const exportButton = () => {
        let csvContent = "data:text/csv;charset=utf-8,";
        for (let i = 0; i < mock_results.allcontacts.length; i++) {
            for (let j = 0; j < mock_results.allcontacts[i].emails.length; j++) {
                let name_email = mock_results.allcontacts[i].emails[j].split("l90l");
                let em = "";
                if (name_email.length > 2) {
                    em = name_email[2] + "," + name_email[1] + "," + name_email[0];
                }
                else if (name_email.length > 1) {
                    em = name_email[1] + "," + name_email[0];
                }
                else {
                    em = name_email[0];
                }
                csvContent += mock_results.allcontacts[i].domain + "," + em + "\r\n";
            }
        }
        // console.log(csvContent);
        var encodedUri = encodeURI(csvContent);
        // window.open(encodedUri);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "my_data.csv");
        document.body.appendChild(link); // Required for FF

        link.click();
    };

    return (
        <div>
            <MainNavigation current="Email Sniper Search Engine" />
            <div className="email-box">
                <form className="compare-form-form" onSubmit={submitHandler}>
                    <div className="email-form-top">
                        <div className="compare-form-title">
                            <div className="compare-credit compare-instructions"><a href="https://kazanseo.com/blog/kazanseo-email-search-engine-how-it-works/" target="_blank" rel="noreferrer">How it works</a></div>
                            <label>Search Anything...</label>
                            <div className="searcher-subtitle">We'll find the contacts for the websites you want</div>
                            <input type="text" value={domain} onChange={domainChangeHandler} />
                        </div>
                    </div>
                    <div className="email-form-actions">
                        <button type="submit">Search for Emails</button>
                    </div>
                </form>
                <div className="mail-builder">
                    <div className={`${openBuilder ? "" : "hider"}`}>
                        <div className="email-areas">
                            <textarea type="text" value={email_subject} placeholder="subject you'll use" onChange={email_subject_ChangeHandler} />
                            <textarea type="text" value={email_body} placeholder="body you'll use" onChange={email_body_ChangeHandler} />
                        </div>
                    </div>
                    <button onClick={() => setOpenBuilder(!openBuilder)}>{`${openBuilder ? "hide" : "show"} mail builder`}</button>
                </div>
                <div className="click-to-copy">Click any result to copy it into clipboard</div>
                <button className={`${openExport ? "export-csv" : "hider"}`} onClick={exportButton}>
                    Export To CSV
                </button>
                <div>{results}</div>
                <div>{ErrorMessage}</div>
                <div className="previous-next">
                    <button className="nav-button" onClick={prev_click}>
                        Prev
                    </button>
                    <div className="current-page">{currentPage}</div>
                    <button className="nav-button" onClick={next_click}>
                        Next
                    </button>
                </div>
            </div>
        </div>
    );
}

export default EmailSearcherSearchEngine;
